// @flow
import * as React from 'react';
import './LetsTalk.css';

export const LetsTalk = () => {
  return (
    <div className="lets-talk">
      I am currently available for freelance work. Contact me at
      andreas.k.savva@gmail.com.
    </div>
  );
};
